import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $dateTimezone,
  $convertNumberFormat,
  $convertHourFormat,
  $convertDateFormat,
  $convertDateKoreanFormat,
  $convertMarkdownToHtml,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import InquiryModal from "../component/InquiryModal";
import ReservationModal from "../component/ReservationModal";

function Chat() {
  const navigate = useNavigate();
  const { isLoggedIn, logout, userData } = useAuth();
  const [theme, setTheme] = useState("light");
  const [inputMessage, setInputMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showMain, setShowMain] = useState(true);
  const [messageList, setMessageList] = useState([]);
  const [showReservationModal, setShowReservationModal] = useState(false);
  const [activeReservation, setActiveReservation] = useState(null);
  const [showInquiryModal, setShowInquiryModal] = useState(false);
  const [golfclubInfo, setGolfclubInfo] = useState(null);

  const btnBookingRef = useRef(null);
  const scrollRef = useRef(null);
  const btnInquiryRef = useRef(null);
  const btnInquiryCloseRef = useRef(null);

  const openReservationModal = (item) => {
    btnBookingRef.current.click();
  };

  const sendMesssage = async () => {
    const tempMessageList = [...messageList];
    const tempInputMessage = inputMessage;
    setInputMessage("");
    setMessageList([
      ...messageList,
      {
        role: "user",
        type: "",
        content: tempInputMessage,
      },
    ]);

    setLoading(true);

    const sendMessages = [];
    messageList.forEach((item, index) => {
      if (item.type === "booking") {
        // sendMessages의 마지막 요소 제거
        sendMessages.pop();
      }

      if (
        item.type !== "booking" &&
        item.type !== "other" &&
        item.type !== ""
      ) {
        sendMessages.push({ ...item, content: JSON.stringify(item.content) });
      }
    });

    const res = await $post("/api/chat", {
      requestMessages: [
        ...sendMessages,
        { role: "user", type: "", content: tempInputMessage },
      ],
    });

    // console.log("res", res);
    if (res.status === 200) {
      setMessageList([
        ...tempMessageList,
        { role: "user", type: "", content: tempInputMessage },
        res.data,
      ]);

      // console.log(res.data);
    }

    setLoading(false);
  };

  const getGolfclubInfo = async () => {
    const res = await $get(`/api/golf/${userData.golfCode}`);
    if (res.status === 200) {
      setGolfclubInfo(res.data);
      if (messageList.length === 0 && res.data.length > 0) {
        messageList.push({
          role: "assistant",
          type: "",
          content:
            res.data[0].chat_anounce ||
            `<p>안녕하세요 고객님. 오늘도 <strong>${userData.golfName}</strong>을 찾아주셔서 진심으로 감사합니다.</p> <p>AI 챗봇을 다음과 같이 사용할수 있습니다.</p><ul><li>다음주 금요일 예약 가능한가요?</li><li>8월 1일 예약 가능한가요?</li><li>그린피 정상가는 얼마인가요? </li><li>골프장 내에 식당은 있나요?</li>`,
        });
      }
    }
  };

  useEffect(() => {
    getGolfclubInfo();
  }, [userData]);

  // useEffect(() => {
  //   if (messageList.length === 0 && userData.golfName) {
  //     messageList.push({
  //       role: "assistant",
  //       type: "",
  //       content: `<p>안녕하세요 고객님. 오늘도 <strong>${userData.golfName}</strong>을 찾아주셔서 진심으로 감사합니다.</p> <p>AI 챗봇을 다음과 같이 사용할수 있습니다.</p><ul><li>다음주 금요일 예약 가능한가요?</li><li>8월 1일 예약 가능한가요?</li><li>그린피 정상가는 얼마인가요? </li><li>골프장 내에 식당은 있나요?</li>`,
  //     });
  //   }
  // }, [userData]);

  useEffect(() => {
    // console.log("messageList", messageList);
    // scrollRef를 제일 밑으로 내려줌
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [messageList]);

  return (
    <div className="col-12 col-lg-9 col-xl-10 main-content" ref={scrollRef}>
      <nav className="navbar navbar-expand-lg sticky-top">
        <div className="container-fluid flex justify-space-between">
          <button
            className="btn btn-outline-secondary d-lg-none"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#sidebarMenu"
            aria-controls="sidebarMenu"
          >
            <i className="fas fa-bars" />
          </button>
          {userData && (
            <a className="navbar-brand ms-3" onClick={() => navigate("/chat")}>
              골프 예약 및 문의
            </a>
          )}
          <div className="d-flex align-items-center me-3">
            {/* <div className="form-check form-switch me-3">
              <input
                className="form-check-input"
                type="checkbox"
                id="darkModeToggle"
                onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
              />
              <label className="form-check-label" htmlFor="darkModeToggle">
                <i className="fas fa-moon" />
              </label>
            </div> */}
            <button
              className="btn btn-outline-secondary"
              onClick={() => setShowInquiryModal(true)}
              title="별도 문의"
            >
              <i className="fa-solid fa-person-circle-question"></i>
            </button>
            {/* <button className="btn btn-outline-secondary me-2">
              <i className="fas fa-copy" />
            </button>
            <button className="btn btn-outline-secondary">
              <i className="fas fa-share" />
            </button> */}
          </div>
        </div>
      </nav>
      <div className="chat-wrapper">
        <div className="chat-container" id="chatMessages">
          {/* Chat messages will be appended here */}
          {messageList &&
            messageList.length > 0 &&
            messageList.map((message, index) => (
              <div
                className={
                  message.role === "assistant" ? "message bot" : "message user"
                }
                key={index}
              >
                <div className="message-text">
                  {message.type === "booking" && message.content.length > 0 ? (
                    <div>
                      <p>
                        다음은{" "}
                        <b>
                          {$convertDateKoreanFormat(message.content[0].date)}
                        </b>{" "}
                        예약 가능 목록입니다. 원하는 시간을 선택하면, 예약을
                        진행할 수 있습니다.
                      </p>
                      <div className="d-md-none">
                        {message.content.map((content, index2) => (
                          <div
                            key={index2}
                            className="card mb-2"
                            onClick={() => {
                              if (
                                content.date &&
                                content.time &&
                                content.course_name &&
                                content.price
                              ) {
                                setActiveReservation(content);
                                setShowReservationModal(true);
                              } else {
                                Swal.fire({
                                  icon: "info",
                                  title: "예약 가능한 시간이 없습니다.",
                                });
                              }
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <div className="card-body">
                              <p
                                className="card-text d-flex justify-content-between"
                                style={{
                                  fontSize: "1.2rem",
                                  fontWeight: "bold",
                                }}
                              >
                                <span>
                                  {$convertHourFormat(content.time) || "없음"}
                                </span>
                                <span>
                                  {$convertNumberFormat(content.price) + "원" ||
                                    "없음"}
                                </span>
                              </p>
                              <p
                                className="card-text"
                                style={{ fontSize: "0.9rem" }}
                              >
                                {content.course_name || "없음"} /{" "}
                                {content.person}인 이상 /{" "}
                                <span
                                  style={{
                                    color:
                                      content.type_name === "셀프"
                                        ? "red"
                                        : content.type_name === "드라이빙"
                                        ? "blue"
                                        : "black",
                                  }}
                                >
                                  {content.type_name}
                                </span>
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="table-responsive d-none d-md-block">
                        <table className="table table-border table-hover text-center text-nowrap mt-2">
                          <thead>
                            <tr>
                              <th>시간</th>
                              <th>코스</th>
                              <th>1인가격</th>
                              <th>최소인원</th>
                              <th>타입</th>
                            </tr>
                          </thead>
                          <tbody>
                            {message.content.map((content, index2) => (
                              <tr
                                key={index2}
                                onClick={() => {
                                  if (
                                    content.date &&
                                    content.time &&
                                    content.course_name &&
                                    content.price
                                  ) {
                                    setActiveReservation(content);
                                    setShowReservationModal(true);
                                  } else {
                                    Swal.fire({
                                      icon: "info",
                                      title: "예약 가능한 시간이 없습니다.",
                                    });
                                  }
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <td>
                                  {$convertHourFormat(content.time) || "없음"}
                                </td>
                                <td>{content.course_name || "없음"}</td>
                                <td>
                                  {$convertNumberFormat(content.price) ||
                                    "없음"}
                                </td>
                                <td>{content.person}</td>
                                <td
                                  style={{
                                    color:
                                      content.type_name === "셀프"
                                        ? "red"
                                        : content.type_name === "드라이빙"
                                        ? "blue"
                                        : "black",
                                  }}
                                >
                                  {content.type_name}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <p>
                        예약 가능 목록에서 원하는 예약을 선택하면, 예약을 진행할
                        수 있습니다.
                      </p>
                    </div>
                  ) : message.type === "booking" &&
                    message.content.length === 0 ? (
                    <p>
                      예약 가능한 날짜/시간이 없습니다.{" "}
                      <a
                        className="underline text-danger bold"
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowInquiryModal(true)}
                      >
                        별도 문의
                      </a>
                      를 통해 원하시는 예약 날짜/시간을 남겨주시면, 최대한 빨리
                      확인 후 답변드리겠습니다.
                    </p>
                  ) : message.type === "other" ? (
                    <p>
                      답변할 수 없는 질문입니다. 별도 문의를 원하시면{" "}
                      <a
                        className="underline text-danger bold"
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowInquiryModal(true)}
                      >
                        여기
                      </a>
                      를 클릭해서 담당자에게 문의를 남기세요. 최대한 빠르게 확인
                      후 연락드리겠습니다.
                    </p>
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: $convertMarkdownToHtml(message.content),
                      }}
                    ></div>
                  )}
                </div>
              </div>
            ))}

          {loading && (
            <div className="message bot">
              <div className="message-text">
                <p>{<BeatLoader color="#10a37f" />}</p>
              </div>
            </div>
          )}
        </div>
        <div className="chat-input-container sticky-bottom">
          <div className="input-group">
            <input
              type="text"
              id="messageInput"
              className="form-control chat-input"
              placeholder="Send a message..."
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  if (e.nativeEvent.isComposing) {
                    return;
                  }
                  if (e.target.value.trim() === "") {
                    return;
                  }
                  sendMesssage();
                }
              }}
            />
            <button
              className="btn send-button"
              id="sendButton"
              onClick={(e) => {
                if (inputMessage.trim() === "") {
                  return;
                }
                sendMesssage();
              }}
            >
              <i className="fas fa-paper-plane" />
            </button>
          </div>
        </div>
      </div>
      {/* modal for booking: 예약 고객명(text), 고객 연락처(text), 라운딩인원(select):3명 또는 4명, 부가서비스(checkbox):카트, 캐디, 특이사항(textarea) */}

      {showReservationModal && (
        <ReservationModal
          reservation={activeReservation}
          golfCode={userData.golfCode}
          golfName={userData.golfName}
          onClose={() => setShowReservationModal(false)}
        />
      )}

      {/* modal for 별도 문의 */}
      {showInquiryModal && (
        <InquiryModal
          golfCode={userData.golfCode}
          onClose={() => setShowInquiryModal(false)}
        />
      )}
    </div>
  );
}

export default Chat;
